@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Aleo:ital@0;1&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@400;600&display=swap'); */

*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
  
}


*::-ms-scrollbar {
  display: none;
}

.takeaway::-webkit-scrollbar {
  display: block;
  width: 5px;
  background-color: #2F669D;
}

.takeaway::-webkit-scrollbar-thumb{
  background-color: #77AADC; /* Color of the thumb */
  border-radius: 2px; /* Border radius of the thumb */
}


body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box-glow {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid #7FC241;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: 0px 0px 10px 0px #7FC241;
  border: 1px;
  border-color: #7FC241;
}

.user_goal:focus {
  outline: 1px solid #7FC241;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: 0px 0px 10px 0px #7FC241;
  border: 1px;
  border-color: #7FC241;
}

.goal:focus {
  outline: 1px solid #7FC241;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: 0px 0px 7px 0px #7FC241;
  border: 1px;
  border-color: #7FC241;
}


.name:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  border-color: #7FC241;
}

.welcome:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #E06064;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  border-color: #E06064;
}

.phone_no{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.phone_no:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  border-color: #7FC241;
}

.answer_text:focus {
  outline: 0px solid transparent;
  outline-offset: 0px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: none;
  --tw-ring-color: none;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  border-color: #fff;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink-animation 0.3s infinite;
}

.locked {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.locked::after {
  content: ''; /* Unicode for lock symbol, you can replace it with your desired lock symbol */
  font-size: 24px;
}

@keyframes shake {
  0% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.locked:active {
  animation: shake 0.4s ease-in-out;
}

@keyframes arrow {
  0% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.arrow-move {
  animation: arrow 3s infinite;
}

.glow {
  animation: glowing 2000ms infinite;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #7FC241;
  }
  50% {
    box-shadow: 0 0 22px #7FC241;
  }
  100% {
    box-shadow: 0 0 5px #7FC241;
  }
}


.btn--glow {
  position:relative;
  overflow:hidden;
}

.btn--glow:before{
  content:'';
  position: absolute;
  left: -20%;
  top:-10%;
  width: 0;
  height: 100%;
  transform: rotate(15deg) scale(1, 2);
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.7);
  animation: button-glow 2000ms ease-in-out infinite;
}

.user_course:focus {
  outline: 1px solid #7FC241;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: 0px 0px 7px 0px #7FC241;
  border: 1px;
  border-color: #7FC241;
}

@keyframes button-glow {
  0% {
    left: -20%;
  }
  50% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

@keyframes down-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.down {
  animation: down-move 1500ms ease-in-out infinite;
}

[type='checkbox']:checked:hover, [type='checkbox']:checked:focus, [type='radio']:checked:hover, [type='radio']:checked:focus {
  border-color: none;
  background-color: #7FC241;
}

[type='checkbox']:focus, [type='radio']:focus {
  outline: none;
  outline-offset: 0;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: none;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  font-size: 1rem !important;
  border: 1.4px solid #7FC241;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 40rem #2D4F71 inset;
}

.shine {
  animation: shining 2000ms infinite;
}

@keyframes shining {
  0% {
    box-shadow: 0 0 5px #F59E0B;
  }
  50% {
    box-shadow: 0 0 22px #F59E0B;
  }
  100% {
    box-shadow: 0 0 5px #F59E0B;
  }
}

